
.login-component {
    @apply flex flex-col items-center pt-[25%] bg-[#ffff] md:flex-row bg-[url('/src/application/assets/images/bg.jpg')] md:pt-0 bg-center bg-cover  h-[100vh]
}

.login-component-containerOne {
    @apply md:w-[55%] md:flex md:flex-col items-center whitespace-nowrap 
}

.light-text {
    @apply text-lg md:text-xl font-[500] tracking-[0.1rem] md:tracking-[0.5em] md:opacity-[100%] md:mt-6;
}

.login-btn {
    @apply flex justify-center md:justify-end bg-theme-color  items-center
}

.text {
    @apply tracking-[0.05em] md:tracking-[0.1em] font-bold text-xl md:text-2xl md:text-2xl ml-3 ;
}

.login-component-containerTwo {
    @apply w-[90%] md:w-[45%] md:h-[100vh] flex h-full items-start md:items-center
}

.background-white {
    @apply flex w-full justify-center  md:bg-[#fff] md:w-[95%] md:h-[95%] md:rounded-3xl md:shadow-lg
}

.bold-text {
    @apply md:font-[1200] md:tracking-[0.3em] md:font-extrabold md:text-xl md:opacity-[100%];
}

.login-input {
    @apply bg-[#EEF0F2] w-full h-[50px]  outline-none p-2 tracking-[0.2rem] font-[500]
}

.login-input:focus {
    transition: all .7s;
    border-bottom: 1.9px solid #16A34A;
}

.login-btn{

    @apply flex text-[white]  w-full justify-center font-[600] py-2 md:py-3 text-2xl tracking-[0.2rem] rounded-full
}

.admin-link{
    @apply  text-theme-color opacity-[60%] hover:opacity-[100%] cursor-pointer underline transition-all duration-200
}

.jalsa-fonty{
    color: rgb(25, 104, 9);
        font-size: 20px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 1000;

}

.user {
    position: absolute;
    width: 280px;
    height: 32px;
    left: 44px;
    top: 80px;
    background: #ffffff;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}
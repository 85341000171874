/* Rotate the image */
@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Fade in and color change animation for the text */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes colorChange {
    0% {
      color: #34D399; /* Green */
    }
    25% {
      color: #F59E0B; /* Amber */
    }
    50% {
      color: #3B82F6; /* Blue */
    }
    75% {
      color: #EF4444; /* Red */
    }
    100% {
      color: #34D399; /* Back to Green */
    }
  }
  
  /* Apply animations */
  .animate-fadeInUp {
    animation: fadeInUp 1s ease-in-out forwards;
  }
  
  .animate-colorChange {
    animation: colorChange 5s ease-in-out infinite;
  }
  
.card {
    @apply h-[500px] w-[350px] border-[1px] bg-[#14147a];
    /* background-image: linear-gradient(to right bottom, rgba(150, 50, 133, 0.8), rgba(54, 5, 44, 0.8)), url("../../../application/assets/images/Jalsa2023.jpeg"); */
    background-image: url("../../../application/assets/images/Jalsa2024.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.tag-hole{
    @apply h-[5%] flex items-center justify-center
}

#hole{
    @apply bg-[#f8f9fa] rounded-full w-10 h-2.5
}




.info{
    max-width: 100%;    
    margin-top: 17pc;
}
.others{
    display: flex;
    align-items: center;
    margin-top: 14px;
    margin-left: 1pc;
}
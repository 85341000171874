.table{
    @apply w-[100%] mt-[15px] 
}

.tbody{
    /* text-align: center; */

}

.tr-body{
    @apply  h-[60px] text-[12.5px] md:text-[16px] md:tracking-[0.1rem] whitespace-nowrap
}

.tr-head{
    @apply whitespace-nowrap text-[11px] md:text-[14px] tracking-[0.1rem];
    text-align: left;
    
}



.next-prev{

    @apply font-bold text-[10px] ml-2;
    color: white;
    padding: 0.8rem;
    border-radius: 0.5rem;
}

.next-prev.inactive{
    color: black;
    opacity: 49%;
    background-color: white;
}

.tr-hover-effect:hover{
    cursor: pointer;
    opacity: 49%;
}


.side-bar{
    @apply  h-full  flex-col w-full md:w-[300px] bg-gray-800 text-white shadow-lg  hidden md:flex;
}

.side-heading{
    @apply tracking-[0.5em] font-bold text-xl  md:font-[800] ml-3 opacity-[49%];
}

.side-options{
    @apply flex mb-[40px] items-center text-[15px] hover:opacity-[49%];
}

.side-options.active{
    @apply border-2 rounded-[0.5em] justify-center hover:opacity-[100%] items-center h-[10%] w-[80%]  bg-[#16a34a60]
}

.side-option-text{
    @apply tracking-[0.1em] p-3 opacity-[49%]  cursor-pointer transition-all duration-300 ;
}
.side-option-text-sm{
    @apply tracking-[0.1em] text-[14px] opacity-[49%] hover:opacity-[100%] cursor-pointer transition-all duration-500 ;
}

.side-option-container{
    @apply flex flex-col items-center mt-[20px] w-full h-[75%]  font-bold;
}

.side-option-mini-container{
    @apply flex flex-col w-[85%]  basis-[100%] ;
}

.top-heading-container{
    @apply flex md:block ;
}

.top-heading{
  @apply  tracking-[0.2em] font-bold text-lg ml-3 opacity-[49%];
}

.name-container{
    @apply md:pt-6 md:pb-2 pr-2 flex items-center justify-end
}

.name{
    @apply md:text-lg text-[10px] font-bold tracking-wider mr-2;
}

.fa-cancel{
    @apply justify-end md:hidden opacity-[49%] mr-2 hover:opacity-[100%];
}

.breakline{
    @apply text-center border-b-[1px] border-black opacity-[20%];
}

.bottom-option-mini-container{
    @apply items-center justify-center w-full h-full flex 
}

.bottom-options{
    @apply flex flex-col  items-center basis-[30%] opacity-[49%] hover:opacity-[100%] duration-200    
}

.bottom-options .text
{
    display: none;
}

.bottom-options.active .text{
    @apply text-[12px] opacity-[100%]  tracking-[0.05em] font-bold relative ;
    display: block;
    transform: translateY(-20px);
}
.bottom-options.active{
    @apply   opacity-[100%] ;
    
}

.bottom-options.active .icon{
    position: relative;
    transform: translateY(-25px);
    border-radius: 100px;
    /* background-color: red; */
    color: white;
    border: 6px solid #EEF0F2;
    padding: 0.8em;
}

.pop-up-container{
  @apply  flex flex-col bg-[white] items-center py-[0.5em] shadow-lg fixed top-[4.2em] z-[20] right-[1.6em]
}

.pop-logout{
    @apply  md:hidden hover:bg-gray-200 px-[1em] py-[0.3em] flex font-bold tracking-[0.1rem] cursor-pointer transition-all duration-300 hover:opacity-[49%] items-center text-[13px]
}
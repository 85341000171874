.topnav-container{
    @apply md:m-2 mt-3 md:mt-5 flex  w-full overflow-x-auto 
}


.topnav-item{
    @apply flex flex-1 flex-col opacity-[49%] text-[13px] md:text-[17px] items-center tracking-[0.05rem]  md:tracking-[0.1rem] font-bold cursor-pointer mx-[10px] whitespace-nowrap
    
}
.topnav-item.active{
    @apply opacity-[100%] 
}
.topnav-item.active:after{
    
    content: "";
    width: 50%;
    margin-top: 3px;
    
}


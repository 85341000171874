.add-manifest-container {
    @apply flex flex-col h-[92%] w-full justify-center items-center overflow-y-auto
}


.add-manifest-minicon {
    @apply h-full w-full md:w-[90%]
}

.add-manifest-title {
    @apply flex justify-center h-[8%] items-center
}

.add-manifest-inputs {
    @apply min-h-[40%] md:h-[36%] mb-[10px] md:mb-[0px]
}

.add-manifest-members {
    @apply bg-[white] min-h-[50%] md:h-[45%] rounded-xl z-20 p-2 md:p-3
}

.add-manifest-members-btn {
    @apply p-1 px-2 md:p-2 font-[500] text-[12px] md:text-[14px] text-white  rounded-lg hover:bg-[#2cc32c] transition-all duration-300
}

.add-manifest-button {
    @apply h-[10%] flex items-center justify-center
}

.logo-div2{
   @apply h-[15%] flex
}

.logo2{
    @apply w-[15%] flex  items-center justify-center h-full
}

.main-text{
    @apply text-[17px] font-bold tracking-[0.1rem] 
}

.mini-text{
    @apply text-[14px] font-bold opacity-[60%]
}


.small-text{
    @apply text-center font-[500] text-black text-[17px]
}

.manifest-details{
    @apply grid p-2 md:gap-x-[5rem] 
}

.qrCode{
    @apply float-right p-10 h-20 w-24
}

.slip-head{
    @apply font-[600]
}

.slip-body{
    @apply ml-2
}

.grid-col{
    grid-template-columns: repeat(2, minmax(0, auto));
}
.register-component {
    @apply grid gap-6 pt-4 md:justify-center md:gap-x-[12rem] md:gap-y-14
}

.register-btn {
    @apply pt-8 flex w-[90%] justify-end
}

.view-guest-btn {
    @apply pt-2 flex w-[90%] justify-end
}

@media screen and (min-width: 768px) {
    .grid-column {
        grid-template-columns: repeat(2, minmax(0, auto));
    }
}